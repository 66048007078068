import React, { useState } from "react";
import "../Styles/MainMenu.css";
import { HiShoppingCart } from "react-icons/hi";
import { useParams, generatePath } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackgroundImage } from "react-image-and-background-image-fade";

function MenuItem(props) {
  let history = useNavigate();
  const { t, i18n } = useTranslation();
  const id = props.id;

  //on choosing menu item user is redirected to /meals (SingleProduct component in /Pages) with menu id as params
  const handleProceed = (e) => {
    history(
      generatePath("/meals/:id", {
        id,
      })
    );
  };

  return (
    <div className="col-lg-4 col-sm-6">
      <div className="p-3 mmMenuItems">
        <p className="mmName"> {props.name} </p>{" "}
        {/*  <div  className='menuImage' style={{ backgroundImage: `url(${props.image})` }}>

                    </div> */}
        <BackgroundImage src={props.image} width="100%" className="menuImage" />
        <p className="mmDescription"> {props.describe} </p>{" "}
        <p className="mmPrice">
          {" "}
          {props.price} {props.currency}{" "}
        </p>{" "}
        <button disabled={props.active !== 1} onClick={handleProceed}>
          {" "}
          <HiShoppingCart /> {t("party.addToBasket")}{" "}
        </button>{" "}
      </div>{" "}
    </div>
  );
}
export default MenuItem;
