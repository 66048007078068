import axios from "axios";
import AxiosProvider3 from "./AxiosProvider3";
import { date } from "react-i18next/icu.macro";

export const phones = (data) => {
  return AxiosProvider3.get("tttttt/mdiaf_phones", data);
};

export const countries = (data) => {
  return AxiosProvider3.get("location/countrys", data);
};
export const countryCities = (id) => {
  return AxiosProvider3.get(`location/countrys?include=states`);
  // return AxiosProvider3.get(`location/countrys/activelystats`);
};
export const getBanners = (props) => {
  return AxiosProvider3.get(`advert/madverts`);
};

export const SMS = (headers) => {
  return AxiosProvider3.get("ttttttt/phone-verification-SMS-send", {
    headers,
  });
};

export const CustomerInfo = (token) => {
  return AxiosProvider3.get(`/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const account = (values) => {
  return AxiosProvider3.post("auth/register", values, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};
export const sendActivation = (values) => {
  return AxiosProvider3.post("auth/sendactivation", values, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};
export const login = (values) => {
  return AxiosProvider3.post("auth/login", values, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};
export const accessToken = (token) => {
  return AxiosProvider3.post(
    "auth/access_token",
    {},
    {
      headers: {
        // "Content-Type": "multipart/form-data",
        Accept: "/",
        Authorization: `Bearer ${token}`,
        // Authorization: ["Bearer " + token],
      },
    }
  );
};
export const contact = (values) => {
  return AxiosProvider3.post("/ttttttt/contact-us", values, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const otp = (values, token) => {
  return AxiosProvider3.post("tttttttt/verify-user", values, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const resendCode = (params) => {
  return AxiosProvider3.get("ttttttttt/OTP-SMS-send", {
    params,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
export const checkPhone = (params) => {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  return AxiosProvider3.get("tttt/check-phone", {
    params,
    headers,
  });
};

export const forgetOTP = (data) => {
  let headers = {
    "Content-Type": "application/json",
  };
  let params = {
    type: "OTP",
  };

  return AxiosProvider3.post("tttt/get-token", data, {
    params,
    headers,
  });
};

export const sendBasket = (values, token) => {
  return AxiosProvider3.post("tttt/inquires", values, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

// Todo: add change password from profile page 
export const changePassword = (values, token) => {
  return AxiosProvider3.put("/me/change-password", values, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const changePassCustomer = (token) => {
  return AxiosProvider3.get("tttt/account-info", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

// !!!!!!!!!!!!!!!!!!! profile !!!!!!!!!!!!!!!11
export const updateCustomer = (values, token) => {
  return AxiosProvider3.put("tttt", values, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const book = (values, token) => {
  return AxiosProvider3.post("tttt-orders", values, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};
export const singleOrder = (id, token) => {
  return AxiosProvider3.get("ttttt-orders/" + id, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const Banners = (type, language, countryId) => {
  return AxiosProvider3.get(`advert/madverts`);
};

export const clients = () => {
  return AxiosProvider3.get("ttttt/clients", {
    headers: {
      Accept: "application/json",
    },
  });
};

export const salesMenu = (id) => {
  return AxiosProvider3.get("ttttt/menus", {
    headers: {
      Accept: "application/json",
    },
  });
};
export const salesService = () => {
  return AxiosProvider3.get("tttt/services", {
    headers: {
      Accept: "application/json",
    },
  });
};

export const getDepartments = () => {
  return AxiosProvider3.get("/basic/departments");
};

export const near_branches = (lat, lng) => {
  return AxiosProvider3.get("/basic/departments", {
    params: {
      latitude: lat,
      longitude: lng,
      orderBy: "distance",
      orderDirection: "desc",
    },
  });
};
// export const branchesNear = (lat, lng) => {
//   return AxiosProvider3.get("/basic/departments", {
//     params: {
//       latitude: lat,
//       longitude: lng,
//       orderBy: "distance",
//       orderDirection: "desc",
//     },
//   });
// };
export const departmentsByCity = () => {
  return AxiosProvider3.get("/basic/departments");
};

export const address = (data, token) => {
  return AxiosProvider3.post("/location/address/add", data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const saved_address = (token) => {
  return AxiosProvider3.get("/location/address", {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};
export const delete_address = (id, token) => {
  return AxiosProvider3.delete(`/location/address/delete/` + id, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const categories = (data) => {
  return AxiosProvider3.get("/tags/categories");
};

export const products = (id, deptId) => {
  return AxiosProvider3.get("/shop/products?categorysId=1", {
    params: {
      categorysId: id,
      // departments_id: deptId,
      // categoryMessage: "true",
      // categoryRecommended: "true",
      // applicationId: 1,
    },
  });
};

export const single_product = (id, branchId, token) => {
  return AxiosProvider3.get(`/shop/products/${id}`);
};

export const Add_to_cart = (data, token) => {
  return AxiosProvider3.post(`/shop/carts/add`, data, {
    // body: `token=${token}`,
    headers: {
      // "Content-Type": "multipart/form-data",
      // Accept: "application/json",
      // "Access-Control-Allow-Credentials": true,
      // "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      // "Access-Control-Allow-Methods": "POST, PUT, PATCH, GET, DELETE, OPTIONS",
      // "Access-Control-Allow-Headers": "Content-Type, Authorization",
      // "Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization",
    },
  });
};

export const Get_Basket = (token) => {
  return AxiosProvider3.get(`/shop/carts`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    // params: {
    //   applicationId: 1,
    // },
  });
};
export const Edit_Quantity = (data, token) => {
  // /shop/carts/updateqty/{rowId?}/{quantity?}
  return AxiosProvider3.post(`/shop/carts/updateqty`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      // Accept: "application/json",
    },
  });
};
export const Delete_Item = (rowId, token) => {
  return AxiosProvider3.delete(`/shop/carts/delete?rowId=${rowId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      // Accept: "application/json",
    },
  });
};
export const Clear_cart = (token) => {
  return AxiosProvider3.delete(`/shop/carts/destroy`, {
    headers: {
      Authorization: `Bearer ${token}`,
      // Accept: "application/json",
    },
  });
};
export const Me = (token) => {
  return AxiosProvider3.get(`/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const get_address = (token) => {
  return AxiosProvider3.get("/location/address", {
    headers: {
      Authorization: `Bearer ${token}`,

      // Accept: "application/json",
    },
  });
};

export const orders = (token) => {
  return AxiosProvider3.get(`/orders/orderstypes`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getUserOrders = (token) => {
  // Todo: change API to get just a completed orders
  return AxiosProvider3.get(`/orders/orders`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      include: "order_types"
    }
  });
};

export const branchesByCity = (id, countryId) => {
  return AxiosProvider3.get("/ttttt", {
    params: {
      type: "city",
      class: "branch",
      cityId: id,
      countryId: countryId,
      applicationId: 1,
    },
  });
};

export const carBranches_city = (id) => {
  return AxiosProvider3.get("/ttttt", {
    params: {
      type: "city",
      class: "car",
      cityId: id,
      applicationId: 1,
    },
  });
};

export const payment_methods = (token) => {
  return AxiosProvider3.post(`/shop/checkout2/paymentmethods`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "X-SHOP-ID": 4,
    },
  });
};
export const shipping_methods = (token) => {
  return AxiosProvider3.post(`/shop/checkout2/shippingmethods`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "X-SHOP-ID": 4,
    },
  });
};

export const checkout_step = (token, data, step) => {
  return AxiosProvider3.post(`/shop/checkout2`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "X-SHOP-ID": 4,
      "X-Order-Type": "delivery_shop"
    },
    params: {
      step: step
    }
  });
};


export const checkout_step_details = (token, data) => {
  return AxiosProvider3.post(`/shop/checkout2`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "X-SHOP-ID": 4,
      "X-Order-Type": "delivery_shop"
    },
    params: {
      step: "details"
    }
  });
};


export const payAgain_checkout = (token, paymentToken, orderId) => {
  var body = {
    token: paymentToken,
    applicationId: 1,
  };
  return AxiosProvider3.post(`/ttttttt/${orderId}/repay`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const cancel_request = (message, token) => {
  return AxiosProvider3.post("ttttt", message, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const cancel_direct = (id, token) => {
  let body = {
    type: "canceled",
  };
  return AxiosProvider3.put(`ttttt/${id}`, body, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const publications = () => {
  return AxiosProvider3.get("/tttt", {
    params: {
      type: "magazine",
    },
  });
};

export const medias = () => {
  return AxiosProvider3.get("/tttttt", {
    params: {
      type: "media",
    },
  });
};

export const create_order = (data, token) => {
  return AxiosProvider3.post(`/ttttt/order`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      applicationId: 1,
    },
  });
};


// export const getAllProducts = (branchId) => {
//   return axios.get(`https://rydan-res.com/api/v1/shop/products`);
// };

// Todo: Search form all products
export const getAllProducts = (branchId) => {
  return AxiosProvider3.get('/shop/products?per_page=100');
};

// Todo: Add a order details API
export const getOrderDetails = async (orderId) => {
  const token = window.localStorage.getItem("token");
    return AxiosProvider3.get(`orders/lists/${orderId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
};

// Todo: My Points API
export const getMyPoints = (token) => {
  return AxiosProvider3.get(`/gamify/points/statistical`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

// Todo: My proposals API
export const getMyProposals = (token) => {
  return AxiosProvider3.get(`/proposals/proposals`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};


export const addProposals = (token, data) => {
  return AxiosProvider3.post(`/proposals/proposals/create`,data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getPopupAdvert = () => {
  return AxiosProvider3.get(`advert/madverts?type=popup`);
};