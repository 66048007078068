import React, { useState, useEffect } from "react";
import "../Styles/Home.css";
import decore from "../Images/brown-decore.svg";
import communityDecore from "../Images/community-footer.svg";
import { useTranslation } from "react-i18next";
import certifiedN from "../Images/certified1.png";
import com from "../Images/comm.png";
import logoRemoved from "../Images/logo-removed.png";

import certified2 from "../Images/certified2.png";
import t2 from "../Images/ot2.jpg";
import t3 from "../Images/order-phone.png";
import t1 from "../Images/mobile.png";

import { BeatLoader } from "react-spinners";
import div5 from "../Images/mobile.png";
import ag from "../Images/AppGallery.svg";
import as from "../Images/AppStore.svg";
import gp from "../Images/GooglePlay.svg";
import ChangeType from "../Components/ChangeType";
// import { getAnalytics, logEvent } from "firebase/analytics";

// import { app } from "../Firebase/firebaseConfig";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import Slider from "../Components/Slider";
import i18next from "i18next";
import CountriesModal from "../Components/CountriesModal";
import useGeoLocation from "../Components/useGeoLocation";
import { near_branches, getBanners, Clear_cart, Me, orders } from "../api-nano";
import jsPDF from "jspdf";
import axios from "axios";
import PopupAdvert from "../Components/PopupAdvert";

function Home(props) {
  // const analytics = getAnalytics(app);
  const { t, i18n } = useTranslation();
  const location = useGeoLocation();
  const history = useNavigate();
  const [changeModal, setChangeModal] = useState(false);
  const [countryModal, setCountryModal] = useState(false);
  const [country, setCountry] = useState([]);
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(true);
  const [navigate, setNavigate] = useState();
  const [cartItems, setCartItems] = useState(0);
  const [banners, setBanners] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);

  useEffect(() => {
    orders().then((response) => {
      const data = response.data.data;
      console.log("orders type is", data);
      setOrderTypes(data);
    });
  }, [])

  //fetch slider images
  //fetch customer phone (for use in webengage and analytics)
  useEffect(() => {
    setLoading(true);

    localStorage.setItem("prevScreen", "home");
    const token = localStorage.getItem("token");
    window.scrollTo(0, 0);

    // let language = i18n.language == "ar" ? "ar" : "en";
    // let countryId = props.country == 0 ? 1 : 2;

    // Banners("website", language, countryId).then((response) => {
    //   const data = response.data.data;
    //   // setBanners(data);
    // });

    getBanners().then((response) => {
      const data = response.data.data;
      console.log("banners is", data);
      setBanners(data);
    });

    //logEvent(analytics, 'start');
    if (token) {
      Me(token)
        .then((response) => {
          setPhone(response.data.data.mobile.toString());
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
        });
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [i18n.language, props.country]);

  /*first checks if cart is not empty and user hasn't already chosen an order type or has chosen a different order type
    => a modal is displayed (ChangeType component found in /Components) for user to confirm clearing the cart and changing order type */

  /*else checks if the custom hoook useGeoLocation is given permission to get user lat and lng
if yes near_branches api is called to fetch near branches using user location and will store near branches then user is redirected to /branches screen (FromBranch component found in /Pages)*/

  /*if there are no near branches user is also redirected to /branches screen and nearbranches are set to an empty array*/
  function handleBranches() {
    const token = localStorage.getItem("token");
    let typeId = localStorage.getItem("typeId");
    if (token && props.cartItems !== 0 && typeId && typeId !== 1) {
      setChangeModal(true);
      setNavigate("/branch"); //for handleClear function to know which path to redirect to
    } else {
    //   localStorage.setItem("typeId", 1);
    //   if (location.loaded && location.coordinates) {
    //     near_branches(location.coordinates.lat, location.coordinates.lng).then(
    //       (response) => {
    //         localStorage.setItem(
    //           "nearBranches",
    //           JSON.stringify(response.data.data)
    //         );
    //         localStorage.setItem("addressLat", location.coordinates.lat);
    //         localStorage.setItem("addressLong", location.coordinates.lng);

    //         history("/branch");
    //       }
    //     );
    //   } else {
    //     history("/branch");
    //     localStorage.setItem("nearBranches", JSON.stringify([]));
    //   }


      near_branches(props.latitude, props.longitude).then((response) => {
        let data = {
          arAddress: props.arAddress,
          enAddress: props.enAddress,
          latitude: props.latitude,
          longitude: props.longitude,
          id: props.id,
          // title: props.title,
        };
        // localStorage.setItem("addressEn", data?.enAddress);

        localStorage.setItem("addressData", JSON.stringify(data));

        localStorage.setItem(
          "nearBranches",
          JSON.stringify(response?.data?.data)
        );
        localStorage.setItem("addressId", props.id);
        localStorage.setItem("typeId", 2);
        console.log(response);

        if (response.data?.data?.length == 0) {
          props.setModal(true);
        } else {
          history("/branch");
        }
      });
    }
  }

  /*first checks if cart is not empty and user hasn't already chosen an order type or has chosen a different order type
    => a modal is displayed (ChangeType component found in /Components) for user to confirm clearing the cart and changing order type */

  /*else  user is  redirected to /address screen (AddressPage found in /Pages)*/
  /*if user is not logged in user is redirected to login screen*/
  function handleDelivery() {
    const token = localStorage.getItem("token");
    if (token) {
      let typeId = localStorage.getItem("typeId");
      if (token && props.cartItems !== 0 && typeId && typeId !== 2) {
        setChangeModal(true);
        setNavigate("/address"); //for handleClear function to know which path to redirect to
      } else {
        history("/address");
      }

      // logEvent(analytics, "click_delivery", {
      //   phone: phone,
      // });
      /*
            window.webengage.track("Click Delivery", {
  
              phone: phone
            })*/
    } else {
      history("/login");
    }
  }
  /*first checks if cart is not empty and user hasn't already chosen an order type or has chosen a different order type
 => a modal is displayed (ChangeType component found in /Components) for user to confirm clearing the cart and changing order type */

  /*else  user is  redirected to /car screen (CarDelivery found in /Pages)*/

  function handleCarDelivery() {
    const token = localStorage.getItem("token");
    let typeId = localStorage.getItem("typeId");
    console.log(props.cartItems);

    if (token && props.cartItems !== 0 && typeId && typeId !== 4) {
      setChangeModal(true);
      setNavigate("/car"); //for handleClear function to know which path to redirect to
    } else {
      history("/car");
    }
  }

  //on confirming clearing cart (from ChangeType modal)
  //user cart is cleared
  function handleClear() {
    setLoading(true);

    const token = window.localStorage.getItem("token");

    Clear_cart(token)
      .then((response) => {
        localStorage.removeItem("promo"); //was used to store data that user entered in cart
        localStorage.removeItem("paymentMethod"); //was used to store data that user entered in cart
        localStorage.removeItem("offer");
        //data stored in cartProcess2
        localStorage.removeItem("timeNow");
        localStorage.removeItem("cartMessage");
        localStorage.removeItem("orderDate");
        localStorage.removeItem("deliveryType");
        localStorage.removeItem("cardToken");

        localStorage.setItem("process", 0); //was used to store user's current step in cart, set to 0 to begin steps from the start
        props.setCartChange(!props.cartChange); //triggers api call in (App.js) to rerender cart
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          //used to change cart icon's current value to 0

          props.setCartItem(0);

          localStorage.removeItem("token");
          localStorage.removeItem("userPhone");
          localStorage.removeItem("userName");
          localStorage.removeItem("basket");
          //was used to store user's current step in cart process
          localStorage.removeItem("process");

          history("/login");
        }
      });
  }

  const generatePDF = async () => {
    const doc = new jsPDF();
    doc.text(20, 20, " edrees pdf example");
    doc.text(20, 40, "this is a content of pdf file");
    const pdf = doc.output("blob");
    var mfile = new File([pdf], "test.pdf", { type: pdf.type });
    const formData = new FormData();
    formData.append("PdfFile", pdf);
    formData.append("PrinterPath", "\\\\" + "DESKTOP-4PUU0JT\\PDFCreator");
    await axios
      .post("http://localhost:7000/print/from-pdf", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        console.log("PDF uploaded successfully");
      })
      .catch((error) => {
        console.error("Error uploading PDF:", error);
      });
  };
  return (
    <div className="Home">
      <PopupAdvert />
      {countryModal ? (
        <CountriesModal country={country} setModal={setCountryModal} />
      ) : null}{" "}
      {changeModal ? (
        <ChangeType
          text="change_type"
          handleClear={handleClear}
          navigate={navigate}
          setModal={setChangeModal}
        />
      ) : null}{" "}
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#ffea13"} loading={loading} size={30} />
        </div>
      ) : (
        <>
        
          <div>
            <Slider banners={banners} type="website" />
          </div>{" "}
          <div className="thirdDiv container-fluid fluid firstDiv">
            <h1 className="how"> {t("home.how")} </h1>{" "}
            <h1 className="how-detail"> {t("home.how-detail")} </h1>
            <div
              style={{
                paddingBottom: "10px",
                marginTop: "2em",
              }}
            >
              {/* <div className="row gy-5">
                <div className="col-md-4 col-sm-6">
                  <div className="menu" onClick={handleBranches}>
                    <div
                      className="menueItem"
                      disabled={orderTypes.length > 0 ? orderTypes[0].is_soon : false}
                      style={{
                        backgroundImage: `url(${t1})`,
                      }}
                    ></div>

                    <div className="menubottom">
                      <button
                        style={{
                          height: "75px",
                        }}
                        className={
                          i18n.language == "en"
                            ? "enArrow-button2 arrow-button2"
                            : "arrow-button2"
                        }
                      >
                        {" "}
                        {t("home.option-branch")}{" "}
                        {orderTypes.length > 0 ? orderTypes[0].name : t("home.option-branch")}
                        <span className="arrow2"> </span>{" "}
                      </button>{" "}
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6">
                  <div className="menu" onClick={handleDelivery}>
                    <div
                      className="menueItem"
                      style={{
                        backgroundImage: `url(${t2})`,
                      }}
                    ></div>

                    <div className="menubottom">
                      <button
                        style={{
                          height: "75px",
                        }}
                        className={
                          i18n.language == "en"
                            ? "enArrow-button2 arrow-button2"
                            : "arrow-button2"
                        }
                      >
                        {" "}
                        {t("home.option-home")}{" "}
                        <span className="arrow2"> </span>{" "}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6">
                  <div
                    className="menu"
                    disabled={orderTypes.length > 0 ? orderTypes[0].is_soon : false}
                    style={orderTypes.length < 0 ? 
                      {}: 
                        orderTypes[0].is_soon ?
                      {
                      cursor: "not-allowed",
                      pointerEvents: "none",
                    }: {}}
                  >
                    <p
                      className={i18n.language == "en" ? "soon soonEn" : "soon"}
                    >
                      {" "}
                      {t("home.soon")}{" "}
                    </p>{" "}
                    <div
                      className="menueItem"
                      style={{
                        backgroundImage: `url(${t3})`,
                        position: "relative",
                      }}
                    >
                      <div id="layer"> </div>{" "}
                    </div>
                    <div
                      className="menubottom"
                      style={{
                        position: "relative",
                      }}
                    >
                      <div id="layer2"> </div>

                      <button
                        disabled
                        style={{
                          height: "75px",
                          cursor: "not-allowed",
                        }}
                        className={
                          i18n.language == "en"
                            ? "enArrow-button2 btnDisabled arrow-button2"
                            : " btnDisabled arrow-button2"
                        }
                      >
                        {" "}
                        {t("home.option-car")} 
                        {orderTypes.length > 0 ? orderTypes[0].name : t("home.option-branch")}
                        <span className="arrow2"> </span>{" "}
                      </button>
                    </div>
                  </div>{" "}
                </div>
              </div>{" "} */}

              <div className="row gy-5">
                <div className="col-md-4 col-sm-6">
                  <div
                    className="menu"
                    onClick={handleDelivery}
                    disabled={orderTypes.length > 0 ? orderTypes[0].is_soon : false}
                    style={!orderTypes.length > 0 ? 
                      {}: 
                        orderTypes[0].is_soon ?
                      {
                      cursor: "not-allowed",
                      pointerEvents: "none",
                    }: {}
                    }
                  >
                    <p
                      className={i18n.language == "en" ? "soon soonEn" : "soon"}
                    >
                      {" "}
                      {/* {t("home.soon")}{" "} */}
                      {!orderTypes.length > 0 ? 
                        '': 
                        orderTypes[0].is_soon 
                          ? t("home.soon")
                          : ''
                      }
                    </p>{" "}
                    <div
                      className="menueItem"
                      style={{
                        backgroundImage: `url(${!orderTypes.length > 0 ? t1 : orderTypes[0].image ? orderTypes[0].image.original : t1})`,
                        position: "relative",
                      }}
                    >
                      <div id={
                        !orderTypes.length > 0 ? 
                        '': 
                        orderTypes[0].is_soon 
                          ?"layer"
                          : ''
                      }> </div>{" "}

                    </div>
                    <div
                      className="menubottom"
                      style={{
                        position: "relative",
                      }}
                    >
                      <div id={
                        !orderTypes.length > 0 ? 
                        '': 
                        orderTypes[0].is_soon 
                          ?"layer2"
                          : ''
                      }> </div>{" "}

                      <button
                        disabled={orderTypes.length > 0 ? orderTypes[0].is_soon : false}
                        style={{
                          height: "75px",
                        }}
                        className={
                          i18n.language == "en"
                            ? "enArrow-button2 btnDisabled arrow-button2"
                            : " btnDisabled arrow-button2"
                        }
                      >
                        {" "}
                        {orderTypes.length > 0 ? orderTypes[0].name : t("home.option-branch")}
                        <span className="arrow2"> </span>{" "}
                      </button>
                    </div>
                  </div>{" "}
                </div>

                <div className="col-md-4 col-sm-6">
                  <div
                    className="menu"
                    onClick={handleBranches}
                    disabled={orderTypes.length > 0 ? orderTypes[1].is_soon : false}
                    style={!orderTypes.length > 0 ? 
                      {}: 
                        orderTypes[1].is_soon ?
                      {
                      cursor: "not-allowed",
                      pointerEvents: "none",
                    }: {}
                    }
                  >
                    <p
                      className={i18n.language == "en" ? "soon soonEn" : "soon"}
                    >
                      {" "}
                      {/* {t("home.soon")}{" "} */}
                      {!orderTypes.length > 0 ? 
                        '': 
                        orderTypes[1].is_soon 
                          ? t("home.soon")
                          : ''
                      }
                    </p>{" "}
                    <div
                      className="menueItem"
                      style={{
                        backgroundImage: `url(${!orderTypes.length > 0 ? t2 : orderTypes[1].image ? orderTypes[1].image.original : t2})`,
                        position: "relative",
                      }}
                    >
                      <div id={
                        !orderTypes.length > 0 ? 
                        '': 
                        orderTypes[1].is_soon 
                          ?"layer"
                          : ''
                      }> </div>{" "}

                    </div>
                    <div
                      className="menubottom"
                      style={{
                        position: "relative",
                      }}
                    >
                      <div id={
                        !orderTypes.length > 0 ? 
                        '': 
                        orderTypes[1].is_soon 
                          ?"layer2"
                          : ''
                      }> </div>{" "}

                      <button
                        disabled={orderTypes.length > 0 ? orderTypes[1].is_soon : false}
                        style={{
                          height: "75px",
                        }}
                        className={
                          i18n.language == "en"
                            ? "enArrow-button2 btnDisabled arrow-button2"
                            : " btnDisabled arrow-button2"
                        }
                      >
                        {" "}
                        {orderTypes.length > 0 ? orderTypes[1].name : t("home.option-home")}
                        <span className="arrow2"> </span>{" "}
                      </button>
                    </div>
                  </div>{" "}
                </div>

                <div className="col-md-4 col-sm-6">
                  <div
                    className="menu"
                    disabled={orderTypes.length > 0 ? orderTypes[2].is_soon : false}
                    style={!orderTypes.length > 0 ? 
                      {}: 
                        orderTypes[2].is_soon ?
                      {
                      cursor: "not-allowed",
                      pointerEvents: "none",
                    }: {}
                    }
                  >
                    <p
                      className={i18n.language == "en" ? "soon soonEn" : "soon"}
                    >
                      {" "}
                      {/* {t("home.soon")}{" "} */}
                      {!orderTypes.length > 0 ? 
                        '': 
                        orderTypes[2].is_soon 
                          ? t("home.soon")
                          : ''
                      }
                    </p>{" "}
                    <div
                      className="menueItem"
                      style={{
                        backgroundImage: `url(${!orderTypes.length > 0 ? t3 : orderTypes[2].image ? orderTypes[2].image.original : t3})`,
                        position: "relative",
                      }}
                    >
                      <div id={
                        !orderTypes.length > 0 ? 
                        '': 
                        orderTypes[2].is_soon 
                          ?"layer"
                          : ''
                      }> </div>{" "}

                    </div>
                    <div
                      className="menubottom"
                      style={{
                        position: "relative",
                      }}
                    >
                      <div id={
                        !orderTypes.length > 0 ? 
                        '': 
                        orderTypes[2].is_soon 
                          ?"layer2"
                          : ''
                      }> </div>{" "}

                      <button
                        disabled={orderTypes.length > 0 ? orderTypes[2].is_soon : false}
                        style={{
                          height: "75px",
                        }}
                        className={
                          i18n.language == "en"
                            ? "enArrow-button2 btnDisabled arrow-button2"
                            : " btnDisabled arrow-button2"
                        }
                      >
                        {" "}
                        {/* {t("home.option-car")}  */}
                        {orderTypes.length > 0 ? orderTypes[2].name : t("home.option-car")}
                        <span className="arrow2"> </span>{" "}
                      </button>
                    </div>
                  </div>{" "}
                </div>
              </div>{" "}



            </div>{" "}
          </div>
          <div>
            <div className="container-fluid fluid certifyDiv">
              <div className="row gy-4">
                <div
                  style={{
                    position: "relative",
                  }}
                  className="col-md-4 certifiedLogo"
                >
                  <div
                    className="div5img"
                    style={{
                      backgroundImage: `url(${certifiedN})`,
                      height: "unset",
                    }}
                  ></div>{" "}
                  <div
                    className="div5img"
                    style={{
                      backgroundImage: `url(${certified2})`,
                      height: "unset",
                    }}
                  ></div>{" "}
                </div>

                <div
                  style={{
                    position: "relative",
                  }}
                  className="col-md-8 div5-2"
                >
                  <div className="certified">
                    <div>
                      <p className="title-2"> {t("iso.iso")} </p>{" "}
                      <p className="title2-2"> {t("iso.iso1")} </p>{" "}
                      {/* <Link to="/iso"> */}
                      {/* <button onClick={generatePDF}> {t("home.more")} </button> */}
                      {/* </Link> */}
                    </div>{" "}
                  </div>{" "}
                  <img
                    src={decore}
                    className={
                      i18n.language == "ar"
                        ? "decoration"
                        : "decoration decorationEn"
                    }
                  />
                </div>
              </div>
            </div>{" "}
          </div>
          <div className="thirdDiv container-fluid fluid">
            <div
              className="row community"
              style={{
                margin: "auto",
              }}
            >
              <div className="col-md-6 comm-image ">
                <div
                  dir="ltr"
                  className="div5img "
                  style={{
                    backgroundImage: `url(${logoRemoved})`,
                    width: "100%",
                    height: "200px",
                  }}
                ></div>{" "}
              </div>
              <div
                style={{
                  position: "relative",
                  marginTop: "0",
                }}
                className="col-md-6 com-text"
              >
                <p className="com-title"> {t("home.social")} </p>{" "}
                <p className="com-details"> {t("home.social-detail")} </p>{" "}
                {/*
                    <button className={i18n.language=="ar"?"arrow-button2":"arrow-button2 enArrow-button2"}>{t("home.more")}<span className="arrow2"></span>
                    </button>*/}{" "}
                <img
                  style={{display: "none"}}
                  src={communityDecore}
                  alt="img"
                  className={
                    i18n.language == "ar"
                      ? "comm-footer"
                      : "comm-footer comm-footerEn"
                  }
                />
              </div>{" "}
            </div>{" "}
          </div>
          <div>
            <div className="container-fluid fluid bottomDiv">
              <div className="row gy-4">
                <div
                  style={{
                    marginTop: "0",
                  }}
                  className="col-md-7  div5-3"
                >
                  <div>
                    <p
                      className={
                        i18n.language == "ar" ? "title-5" : "title-5 title-5En"
                      }
                    >
                      {" "}
                      {t("home.provided")}{" "}
                    </p>{" "}
                    {/*
                     <div style={{alignItems:"center"}} className="div5Buttons row">
                       <div className="col-lg-3 col-md-12 col-sm-3 col-12">
                     <p style={{marginBottom:"0"}} className="title2-5"> {t("home.provided-deatils")}</p>
                     </div> 
                       <div className="col-lg-3 col-md-4 col-sm-3 col-4">
                    <a href="https://play.google.com/store/apps/details?id=com.tech_world.romansia&hl=ar" target="_blank">
                     <button style= {{backgroundImage: `url(${gp})`}}  className="btn5"></button>
                     
                     </a></div>
                     <div className="col-lg-3 col-md-4 col-sm-3 col-4">
                     <a href="https://apps.apple.com/sa/app/%D8%A7%D9%84%D8%B1%D9%88%D9%85%D8%A7%D9%86%D8%B3%D9%8A%D8%A9/id680481004" target="_blank">
                     <button style= {{backgroundImage: `url(${as})`}}  className="btn5"></button>
                     </a>
                     </div>
                     <div className="col-lg-3 col-md-4 col-sm-3 col-4">
                     <a href="https://appgallery.huawei.com/app/C103513027" target="_blank">
                     <button style= {{backgroundImage: `url(${ag})`}} className="btn5"></button>
                     </a></div>
                    </div>*/}{" "}
                    <p
                      style={{
                        marginBottom: "3em",
                      }}
                      className="title2-5"
                    >
                      {" "}
                      {t("home.provided-deatils")}{" "}
                    </p>{" "}
                    {/*<div className="container-fluid" style={i18n.language=="ar"?{paddingRight:"0"}:{paddingLeft:"0"}}>
                     <div style={{alignItems:"center"}} className="div5Buttons row">
                      
                       <div className="col-4">
                    <a href="https://play.google.com/store/apps/details?id=com.tech_world.romansia&hl=ar" target="_blank">
                     <button style= {{backgroundImage: `url(${gp})`}}  className="btn5"></button>
                     
                     </a></div>
                     <div className="col-4">
                     <a href="https://apps.apple.com/sa/app/%D8%A7%D9%84%D8%B1%D9%88%D9%85%D8%A7%D9%86%D8%B3%D9%8A%D8%A9/id680481004" target="_blank">
                     <button style= {{backgroundImage: `url(${as})`}}  className="btn5"></button>
                     </a>
                     </div>
                     <div className="col-4">
                     <a href="https://appgallery.huawei.com/app/C103513027" target="_blank">
                     <button style= {{backgroundImage: `url(${ag})`}} className="btn5"></button>
                     </a></div>
                    </div></div>*/}
                    <div
                      style={{
                        alignItems: "center",
                      }}
                      className="div5Buttons "
                    >
                      <div>
                        <a disabled href="https://play.google.com/store/apps/details?id=com.digitalfood.raydan_restaurant&pcampaignid=web_share" target="_blank">
                          <button
                          disabled
                            style={{
                              backgroundImage: `url(${gp})`,
                              opacity: "0.8"
                            }}
                            className="btn5"
                          >
                            {" "}
                          </button>
                        </a>
                      </div>
                      <span> </span>{" "}
                      <div>
                        <a href="https://apps.apple.com/ye/app/%D9%85%D8%B7%D8%B9%D9%85-%D8%B1%D9%8A%D8%AF%D8%A7%D9%86/id6479952369" target="_blank">
                          <button
                              disabled
                              style={{
                              backgroundImage: `url(${as})`,
                              opacity: "0.8"
                            }}
                            className="btn5"
                          >
                            {" "}
                          </button>{" "}
                        </a>{" "}
                      </div>{" "}
                      <span> </span>{" "}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    position: "relative",
                  }}
                  className="col-md-5 div5-1 order-first order-md-last"
                >
                  <div
                    className="div5img appImg"
                    style={{
                      backgroundImage: `url(${div5})`,
                      width: "100%",
                      height: "400px",
                    }}
                  ></div>{" "}
                </div>
              </div>
            </div>{" "}
          </div>{" "}
          {/* <Footer />{" "} */}  
        </>
      )}
    </div>
  );
}

export default Home;
