import React, { useState, useEffect } from "react";
import "../Styles/OrderNow.css";

import { useTranslation } from "react-i18next";
import home from "../Images/ot2.jpg";
import car from "../Images/order-phone.png";
import branch from "../Images/mobile.png";
import { Clear_cart, Me, orders } from "../api-nano";
// import { getAnalytics, logEvent } from "firebase/analytics";
import ChangeType from "../Components/ChangeType";
//import { analytics } from "../Firebase/firebaseConfig";
//import { firebaseAnalytics } from "../Firebase/firebaseConfig";
// import { app } from "../Firebase/firebaseConfig";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import { BeatLoader } from "react-spinners";
import useGeoLocation from "../Components/useGeoLocation";
import { near_branches, countries } from "../api-nano";
import CountriesModal from "../Components/CountriesModal";

function OrderNow(props) {
  //   const analytics = getAnalytics(app);
  const { t, i18n } = useTranslation();
  const location = useGeoLocation();
  const history = useNavigate();
  const [countryModal, setCountryModal] = useState(false);
  const [country, setCountry] = useState([]);
  const [phone, setPhone] = useState("");
  const [changeModal, setChangeModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [navigate, setNavigate] = useState();
  const [orderTypes, setOrderTypes] = useState([]);

  useEffect(() => {
    orders().then((response) => {
      const data = response.data.data;
      console.log("orders type is", data);
      setOrderTypes(data);
    });
  }, [])

  //fetch customer phone (for use in webengage and analytics)
  useEffect(() => {
    const token = localStorage.getItem("token");
    //logEvent(analytics, 'start');
    if (token) {
      Me(token)
        .then((response) => {
          setPhone(response.data.data.phone.toString());
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  /*first checks if cart is not empty and user hasn't already chosen an order type or has chosen a different order type
 => a modal is displayed (ChangeType component found in /Components) for user to confirm clearing the cart and changing order type */

  /*else  user is  redirected to /car screen (CarDelivery found in /Pages)*/

  function handleCarDelivery() {
    const token = localStorage.getItem("token");
    let typeId = localStorage.getItem("typeId");
    console.log(props.cartItems);

    if (token && props.cartItems !== 0 && typeId && typeId !== 4) {
      setChangeModal(true);
      setNavigate("/car");
    } else {
      history("/car");
    }
  }

  //on confirming clearing cart (from ChangeType modal)
  //user cart is cleared
  function handleClear() {
    setLoading(true);

    const token = window.localStorage.getItem("token");

    Clear_cart(token)
      .then((response) => {
        localStorage.removeItem("promo"); //was used to store data that user entered in cart
        localStorage.removeItem("paymentMethod"); //was used to store data that user entered in cart
        localStorage.setItem("process", 0); //was used to store user's current step in cart, set to 0 to begin steps from the start
        props.setCartChange(!props.cartChange); //triggers api call in (App.js) to rerender cart
        localStorage.removeItem("offer");
        localStorage.removeItem("cardToken");

        setLoading(false);
      })
      .catch((error) => {});
  }

  /*first checks if cart is not empty and user hasn't already chosen an order type or has chosen a different order type
 => a modal is displayed (ChangeType component found in /Components) for user to confirm clearing the cart and changing order type */

  /*else checks if the custom hoook useGeoLocation is given permission to get user lat and lng
if yes near_branches api is called to fetch near branches using user location and will store near branches then user is redirected to /branches screen (FromBranch component found in /Pages)*/

  /*if there are no near branches user is also redirected to /branches screen and nearbranches are set to an empty array*/

  function handleBranches() {
    const token = localStorage.getItem("token");
    if (token) {
      //   logEvent(analytics, "click_branch", {
      //     phone: phone,
      //   });
      /*
            window.webengage.track("Click Branch", {
        
              phone: phone
            })*/
    }
    let typeId = localStorage.getItem("typeId");
    if (token && props.cartItems !== 0 && typeId && typeId !== 1) {
      setChangeModal(true);
      setNavigate("/branch");
    } else {
      localStorage.setItem("typeId", 1);
      if (location.loaded && location.coordinates) {
        near_branches(location.coordinates.lat, location.coordinates.lng).then(
          (response) => {
            localStorage.setItem(
              "nearBranches",
              JSON.stringify(response.data.data)
            );
            localStorage.setItem("addressLat", location.coordinates.lat);
            localStorage.setItem("addressLong", location.coordinates.lng);

            history("/branch");
          }
        );
      } else {
        history("/branch");
        localStorage.setItem("nearBranches", JSON.stringify([]));
      }
    }
  }

  /*first checks if cart is not empty and user hasn't already chosen an order type or has chosen a different order type
    => a modal is displayed (ChangeType component found in /Components) for user to confirm clearing the cart and changing order type */

  /*else  user is  redirected to /address screen (AddressPage found in /Pages)*/
  /*if user is not logged in user is redirected to login screen*/

  function handleDelivery() {
    const token = localStorage.getItem("token");
    if (token) {
      let typeId = localStorage.getItem("typeId");
      if (token && props.cartItems !== 0 && typeId && typeId !== 2) {
        setChangeModal(true);
        setNavigate("/address");
      } else {
        history("/address");
      }

      //   logEvent(analytics, "click_delivery", {
      //     phone: phone,
      //   });
      /*
            window.webengage.track("Click Delivery", {
        
              phone: phone
            })*/
    } else {
      history("/login");
    }
  }

  return (
    <div className="Home">
      {countryModal ? (
        <CountriesModal country={country} setModal={setCountryModal} />
      ) : null}{" "}
      {changeModal ? (
        <ChangeType
          text="change_type"
          navigate={navigate}
          handleClear={handleClear}
          setModal={setChangeModal}
        />
      ) : null}{" "}
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#ffea13"} loading={loading} size={30} />
        </div>
      ) : (
        <div
          style={{
            paddingBottom: "0",
          }}
          className="thirdDiv ordrNw container-fluid fluid"
        >
          {" "}
          {/*  <p className="chooseType">{t("nav.choose_type")}</p>*/}{" "}
          <div className="titleDiv">
            <h1
              style={{
                marginBottom: "0.5em",
              }}
              className="how"
            >
              {" "}
              {t("home.how")}{" "}
            </h1>{" "}
            <h1 className="how-detail"> {t("home.how-detail")} </h1>{" "}
          </div>{" "}
          <div className="container-fluid">
            {/* <div className="row gy-5 ordrTypes">
              <div className="col-sm-4">
                <div className="menu" onClick={handleBranches}>
                  <div
                    className="menueItem"
                    style={{
                      backgroundImage: `url(${branch})`,
                    }}
                  ></div>

                  <div className="menubottom">
                    <button
                      style={{
                        height: "75px",
                      }}
                      className={
                        i18n.language == "en"
                          ? "enArrow-button2 arrow-button2"
                          : "arrow-button2"
                      }
                    >
                      {" "}
                      {t("home.option-branch")}{" "}
                      <span className="arrow2"> </span>{" "}
                    </button>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="menu" onClick={handleDelivery}>
                  <div
                    className="menueItem"
                    style={{
                      backgroundImage: `url(${home})`,
                    }}
                  ></div>

                  <div className="menubottom">
                    <button
                      style={{
                        height: "75px",
                      }}
                      className={
                        i18n.language == "en"
                          ? "enArrow-button2 arrow-button2"
                          : "arrow-button2"
                      }
                    >
                      {" "}
                      {t("home.option-home")} <span className="arrow2"> </span>{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div
                  className="menu"
                  disabled
                  style={{
                    cursor: "not-allowed",
                    pointerEvents: "none",
                  }}
                >
                  <p className={i18n.language == "en" ? "soon soonEn" : "soon"}>
                    {" "}
                    {t("home.soon")}{" "}
                  </p>

                  <div
                    className="menueItem"
                    style={{
                      backgroundImage: `url(${car})`,
                      position: "relative",
                    }}
                  >
                    <div id="layer"> </div>{" "}
                  </div>

                  <div
                    className="menubottom"
                    style={{
                      position: "relative",
                    }}
                  >
                    <div id="layer2"> </div>

                    <button
                      disabled
                      style={{
                        height: "75px",
                        cursor: "not-allowed",
                      }}
                      className={
                        i18n.language == "en"
                          ? "enArrow-button2 btnDisabled arrow-button2"
                          : " btnDisabled arrow-button2"
                      }
                    >
                      {" "}
                      {t("home.option-car")} <span className="arrow2"> </span>{" "}
                    </button>
                  </div>
                </div>{" "}
              </div>{" "}
            </div> */}


              <div className="row gy-5 ordrTypes">

                <div className="col-md-4 col-sm-6">
                  <div
                    className="menu"
                    onClick={handleDelivery}
                    disabled={orderTypes.length > 0 ? orderTypes[0].is_soon : false}
                    style={!orderTypes.length > 0 ? 
                      {}: 
                        orderTypes[0].is_soon ?
                      {
                      cursor: "not-allowed",
                      pointerEvents: "none",
                    }: {}
                    }
                  >
                    <p
                      className={i18n.language == "en" ? "soon soonEn" : "soon"}
                    >
                      {" "}
                      {/* {t("home.soon")}{" "} */}
                      {!orderTypes.length > 0 ? 
                        '': 
                        orderTypes[0].is_soon 
                          ? t("home.soon")
                          : ''
                      }
                    </p>{" "}
                    <div
                      className="menueItem"
                      style={{
                        backgroundImage: `url(${!orderTypes.length > 0 ? branch : orderTypes[0].image ? orderTypes[0].image.original : branch})`,
                        position: "relative",
                      }}
                    >
                      <div id={
                        !orderTypes.length > 0 ? 
                        '': 
                        orderTypes[0].is_soon 
                          ?"layer"
                          : ''
                      }> </div>{" "}

                    </div>
                    <div
                      className="menubottom"
                      style={{
                        position: "relative",
                      }}
                    >
                      <div id={
                        !orderTypes.length > 0 ? 
                        '': 
                        orderTypes[0].is_soon 
                          ?"layer2"
                          : ''
                      }> </div>{" "}

                      <button
                        disabled={orderTypes.length > 0 ? orderTypes[0].is_soon : false}
                        style={{
                          height: "75px",
                        }}
                        className={
                          i18n.language == "en"
                            ? "enArrow-button2 btnDisabled arrow-button2"
                            : " btnDisabled arrow-button2"
                        }
                      >
                        {" "}
                        {orderTypes.length > 0 ? orderTypes[0].name : t("home.option-branch")}
                        <span className="arrow2"> </span>{" "}
                      </button>
                    </div>
                  </div>{" "}
                </div>

                <div className="col-md-4 col-sm-6">
                  <div
                    className="menu"
                    onClick={handleBranches}
                    disabled={orderTypes.length > 0 ? orderTypes[1].is_soon : false}
                    style={!orderTypes.length > 0 ? 
                      {}: 
                        orderTypes[1].is_soon ?
                      {
                      cursor: "not-allowed",
                      pointerEvents: "none",
                    }: {}
                    }
                  >
                    <p
                      className={i18n.language == "en" ? "soon soonEn" : "soon"}
                    >
                      {" "}
                      {/* {t("home.soon")}{" "} */}
                      {!orderTypes.length > 0 ? 
                        '': 
                        orderTypes[1].is_soon 
                          ? t("home.soon")
                          : ''
                      }
                    </p>{" "}
                    <div
                      className="menueItem"
                      style={{
                        backgroundImage: `url(${!orderTypes.length > 0 ? home : orderTypes[1].image ? orderTypes[1].image.original : home})`,
                        position: "relative",
                      }}
                    >
                      <div id={
                        !orderTypes.length > 0 ? 
                        '': 
                        orderTypes[1].is_soon 
                          ?"layer"
                          : ''
                      }> </div>{" "}

                    </div>
                    <div
                      className="menubottom"
                      style={{
                        position: "relative",
                      }}
                    >
                      <div id={
                        !orderTypes.length > 0 ? 
                        '': 
                        orderTypes[1].is_soon 
                          ?"layer2"
                          : ''
                      }> </div>{" "}

                      <button
                        disabled={orderTypes.length > 0 ? orderTypes[1].is_soon : false}
                        style={{
                          height: "75px",
                        }}
                        className={
                          i18n.language == "en"
                            ? "enArrow-button2 btnDisabled arrow-button2"
                            : " btnDisabled arrow-button2"
                        }
                      >
                        {" "}
                        {orderTypes.length > 0 ? orderTypes[1].name : t("home.option-home")}
                        <span className="arrow2"> </span>{" "}
                      </button>
                    </div>
                  </div>{" "}
                </div>

                <div className="col-md-4 col-sm-6">
                  <div
                    className="menu"
                    disabled={orderTypes.length > 0 ? orderTypes[2].is_soon : false}
                    style={!orderTypes.length > 0 ? 
                      {}: 
                        orderTypes[2].is_soon ?
                      {
                      cursor: "not-allowed",
                      pointerEvents: "none",
                    }: {}
                    }
                  >
                    <p
                      className={i18n.language == "en" ? "soon soonEn" : "soon"}
                    >
                      {" "}
                      {/* {t("home.soon")}{" "} */}
                      {!orderTypes.length > 0 ? 
                        '': 
                        orderTypes[2].is_soon 
                          ? t("home.soon")
                          : ''
                      }
                    </p>{" "}
                    <div
                      className="menueItem"
                      style={{
                        backgroundImage: `url(${!orderTypes.length > 0 ? car : orderTypes[2].image ? orderTypes[2].image.original : car})`,
                        position: "relative",
                      }}
                    >
                      <div id={
                        !orderTypes.length > 0 ? 
                        '': 
                        orderTypes[2].is_soon 
                          ?"layer"
                          : ''
                      }> </div>{" "}

                    </div>
                    <div
                      className="menubottom"
                      style={{
                        position: "relative",
                      }}
                    >
                      <div id={
                        !orderTypes.length > 0 ? 
                        '': 
                        orderTypes[2].is_soon 
                          ?"layer2"
                          : ''
                      }> </div>{" "}

                      <button
                        disabled={orderTypes.length > 0 ? orderTypes[2].is_soon : false}
                        style={{
                          height: "75px",
                        }}
                        className={
                          i18n.language == "en"
                            ? "enArrow-button2 btnDisabled arrow-button2"
                            : " btnDisabled arrow-button2"
                        }
                      >
                        {" "}
                        {/* {t("home.option-car")}  */}
                        {orderTypes.length > 0 ? orderTypes[2].name : t("home.option-car")}
                        <span className="arrow2"> </span>{" "}
                      </button>
                    </div>
                  </div>{" "}
                </div>
              </div>{" "}

          </div>{" "}
          {/*<div className="optionsDiv container-fluid"  >
                      <div className="row gy-5">

                      <div className="col-sm-4 ">
                            <div className="menu">
                            <div  className='menueItem' style={{ backgroundImage: `url(${branch})` }}>

                    </div>
                          
                              <div className="menubottom">
                              
                     
                                  
                                  <button onClick={handleBranches} >{t("home.option-branch")}
                      </button>
                      </div></div>
                          </div> 


                          <div className="col-sm-4">
                            <div className="menu">
                                
                            <div  className='menueItem' style={{ backgroundImage: `url(${home})` }}>

                    </div>
                          
                              <div className="menubottom">
                             

                             
                                  <button onClick={handleDelivery} >{t("home.option-home")}<span className="arrow2"></span>
                      </button>

                      
                      </div></div>
                          </div> 

                          <div className="col-sm-4 ">
                            <div className="menu">
                            <div  className='menueItem' style={{ backgroundImage: `url(${car})` }}>

                    </div>
                          
                              <div className="menubottom">
                               

                               
                                  <button onClick={handleCarDelivery} >{t("home.option-car")}<span className="arrow2"></span>
                      </button>

                      
                      </div></div>
                          </div> 

                     
                           
                      </div>
                    </div>*/}{" "}
        </div>
      )}
      {/* <Footer /> */}
    </div>
  );
}

export default OrderNow;
