// import "../Styles/Footer.css";
// import { Link } from "react-router-dom";
// import p1 from "../Images/Footer/payment1.svg";
// import p2 from "../Images/Footer/payment2.svg";
// import p3 from "../Images/Footer/payment3.svg";
// import p4 from "../Images/Footer/payment4.svg";
// import vat from "../Images/Footer/vat.svg";
// import logoAr from "../Images/comm1.png";
// import logoEn from "../Images/comm.png";
// import maroof from "../Images/Maroof.svg";
// import { useTranslation } from "react-i18next";

// function Footer() {
//   const { t, i18n } = useTranslation();
//   var dt = new Date();
//   const year = dt.getFullYear();
//   return (
//     <div id="footer" className="footer container-fluid">
//       <div className="container">
//         <div className="row gy-4">
//           <div className="col-sm-4 footerVat">
//             <img
//               className="footerLogo"
//               src={i18n.language == "ar" ? logoAr : logoEn}
//             />
//           </div>{" "}
//           <div className="col-sm-4 paymentMethods">
//             <img src={p3} /> <img src={p4} />{" "}
//           </div>{" "}
//           <div
//             className={
//               i18n.language == "ar"
//                 ? "col-sm-4 footerEndAr"
//                 : "col-sm-4 footerEnd"
//             }
//           >

//           </div>{" "}
//         </div>{" "}
//         <div className="copyRights">
//           <p>
//             {" "}
//             {t("footer.copy_write_parg")}© {year}{" "}
//           </p>{" "}
//           {/* Todo: add developed company to footer section */}
//           <p>
//             {" "}
//             {t("footer.developed_by")}
//             {": "}
//             <a href="https://www.nano2soft.com/ar" target="_blank" className="developedCompany">{t("footer.developed_company")}</a>
//           </p>{" "}
//         </div>{" "}
//       </div>
//     </div>
//   );
// }

// export default Footer;




import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaFacebookSquare , FaInstagram, FaTwitterSquare } from "react-icons/fa";
import logoAr from "../Images/comm1.png";
import logoEn from "../Images/comm.png";
import "../Styles/Footer.css";
import p3 from "../Images/Footer/payment3.svg";
import p4 from "../Images/Footer/payment4.svg";

function Footer() {
  const { t, i18n } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content container">
        <div className="row">
          <div className="col-md-4">
            <img 
              src={i18n.language == "ar" ? logoAr : logoEn}
              alt="Rydan Logo" 
              className="footer-logo"
            />
            <p className="footer-description">{t("footer.mission")}</p>
          </div>
          <div className="col-md-4">
            <h3>{t("footer.contact")}</h3>
            <p>{t("footer.address")}</p>
            <p>{t("footer.phone")} : 966531833995+</p>
          </div>
          <div className="col-md-4">
            <h3>{t("footer.follow_us")}</h3>
            <div className="social-icons">
              <a href="#" target="_blank" rel="noopener noreferrer"><FaFacebookSquare /></a>
              <a href="#" target="_blank" rel="noopener noreferrer"><FaTwitterSquare /></a>
              <a href="#" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            </div>

            <div className="col-sm-4 payment-methods ">
              <img src={p3} /> <img src={p4} />{" "}
            </div>{" "}
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; {year} {t("footer.copy_write_parg")}</p>
          <p>{t("footer.developed_by")}: <a href="https://www.nano2soft.com/ar" target="_blank" rel="noopener noreferrer" ><span>{t("footer.developed_company")}</span></a></p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;