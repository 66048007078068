import i18next from "i18next";
import "../Styles/UserProfile.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import pp from "../Images/defaultImg.jpg";
import { Me } from "../api-nano";

function UserNav(props) {
  const { t, i18n } = useTranslation();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState();

  //fetch user data
  //rerenders when personal data is edited
  useEffect(() => {
    const token = localStorage.getItem("token");
    window.scrollTo(0, 0);

    Me(token)
      .then((response) => {
        setImage(response.data.data.photo);
        setName(response.data.data.name);
        setPhone(response.data.data.phone);
      })
      .catch((err) => {});
  }, [props.edit]);

  return (
    <div>
      <div className="mobile-upSideNav">
        <div className="mobile-upSideNav-top">
          <div className="upTop">
            <div className="upImageBorder">
              <div
                className="profileImage"
                style={{
                  backgroundImage: `url(${image ? image : pp})`,
                }}
              >
                {" "}
              </div>
            </div>
          </div>
          <div className="upInfo">
            <p className="upInfo-name"> {name} </p>{" "}
            <p className="upInfo-number"> {phone} </p>
          </div>
        </div>{" "}
        <div>
          <hr />
          <div className="mobile-upLinks">
            <Link to="/profile/myOrders">
              <p className={props.nav == 0 ? "mobile-upLinksActive" : null}>
                {" "}
                {t("profile.orders")}{" "}
              </p>
              <span></span>
            </Link>{" "}
            <Link to="/profile/myAddresses">
              <p className={props.nav == 1 ? "mobile-upLinksActive" : null}>
                {" "}
                {t("profile.address")}{" "}
              </p>
              <span></span>
            </Link>{" "}
            <Link to="/profile/myPoints">
              <p className={props.nav == 2 ? "mobile-upLinksActive" : null}>
                {" "}
                {t("nav.points")}{" "}
              </p>
              <span></span>
            </Link>{" "}
            <Link to="/profile/myProposals">
              <p className={props.nav == 4 ? "mobile-upLinksActive" : null}>
                {" "}
                {t("proposal.title")}{" "}
              </p>
              <span></span>
            </Link>{" "}
            
            <Link to="/profile/myData">
              <p
                className={
                  props.nav == 5 || props.nav == 6
                    ? "mobile-upLinksActive"
                    : null
                }
              >
                {" "}
                {t("profile.personal")}{" "}
              </p>{" "}
              <span></span>
            </Link>{" "}
            <p onClick={() => props.setModal(true)}> {t("profile.logout")} </p>{" "}
            {/*
            <p  onClick={()=>props.setNav(0)} className={props.nav==0?"mobile-upLinksActive":null}>المنتجات المفضلة </p>*/}
          </div>{" "}
        </div>{" "}
      </div>{" "}
      <div className="upSideNave">
        <div className="upTop">
          <div className="upImageBorder">
            <div
              className="profileImage"
              style={{
                backgroundImage: `url(${image ? image : pp})`,
              }}
            >
              {" "}
            </div>
          </div>
        </div>
        <div>
          <p className="upName"> {name} </p>{" "}
          <p className="upPhone"> {phone} </p>
        </div>
        <div
          className={i18n.language == "ar" ? "upLinks " : "upLinks upLinksEn"}
        >
          <Link to="/profile/myOrders">
            <p className={props.nav == 0 ? "upLinksActive" : null}>
              {" "}
              {t("profile.orders")}{" "}
            </p>{" "}
          </Link>{" "}
          <Link to="/profile/myAddresses">
            <p className={props.nav == 1 ? "upLinksActive" : null}>
              {" "}
              {t("profile.address")}{" "}
            </p>{" "}
          </Link>{" "}
          <Link to="/profile/myPoints">
            <p className={props.nav == 2 ? "upLinksActive" : null}>
              {" "}
              {t("nav.points")}{" "}
            </p>{" "}
          </Link>{" "}
          <Link to="/profile/myProposals">
            <p className={props.nav == 4 ? "upLinksActive" : null}>
              {" "}
              {t("proposal.title")}{" "}
            </p>{" "}
          </Link>{" "}
          <Link to="/profile/myData">
            <p
              className={
                props.nav == 5 || props.nav == 6 ? "upLinksActive" : null
              }
            >
              {" "}
              {t("profile.personal")}{" "}
            </p>{" "}
          </Link>{" "}
          <p onClick={() => props.setModal(true)}> {t("profile.logout")} </p>{" "}
          {/*
            <p onClick={()=>props.setNav(4)} className={props.nav==4?"upLinksActive":null}>  المنتجات المفضلة</p>
            */}{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
}

export default UserNav;
