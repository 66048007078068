import "../Styles/CartProcess.css";
import Footer from "../Components/Footer";
import { HiShoppingCart } from "react-icons/hi";
import { FaWallet } from "react-icons/fa";
import CartProcess1 from "./CartProcess1";
import CartProcess2 from "./CartProcess2";
import CartProcess3 from "./CartProcess3";
import CartProcess4 from "./CartProcess4";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronDoubleDown } from "react-icons/bs";
import { Get_Basket, checkout_step } from "../api-nano";
import { BeatLoader } from "react-spinners";
import CartProcess31 from "./CartProcess3.1";
// import collect from "collect.js";
// import { getAnalytics, logEvent } from "firebase/analytics";

// import { app } from "../Firebase/firebaseConfig";

function CartProcess(props) {
  const [nav, setNav] = useState(0);
  const [loading, setLoading] = useState(true);
  //   const analytics = getAnalytics(app);

  const { t, i18n } = useTranslation();
  const [meals, setMeals] = useState([]);

  //fetch user's cart details
  useEffect(() => {
    /*
if(nav!==3){ //so when user clicks on cart icon while checking the cart ()=> if user is not on the last step,cart is reset back to first step
  setNav(0)

}*/
    setNav(0);
    const token = window.localStorage.getItem("token");
    if (token) {
      Get_Basket(token)
        .then((response) => {
          let arr = [];
          console.log("the carts is", response);
          response.data?.content?.map((data) =>
            arr.push({
              name: data?.name,
              quantity: data?.qty.toString(),
              item_id: data.id.toString(),
            })
          );

          setMeals(response.data?.content);
          setLoading(false);
        })
        .catch((error) => {
          setMeals([]);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [props.cartChange, props.process]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("process")); //stores the step that the user is currently on
    console.log("the process is", data);
    if (data) {
      setNav(data);
    } else {
      setNav(0);
    }
  }, [nav]);

  var Screen;
  if (nav == 0) {
    Screen = (
      <CartProcess1
        setMeals={setMeals}
        setCartItem={props.setCartItem}
        setCartChange={props.setCartChange}
        cartChange={props.cartChange}
        setNav={setNav}
      />
    );
  } else if (nav == 1) {
    Screen = <CartProcess2 setNav={setNav} />;
  } else if (nav == 2) {
    Screen = (
      <CartProcess31
        setCartItem={props.setCartItem}
        meals={meals}
        setCartChange={props.setCartChange}
        cartChange={props.cartChange}
        setNav={setNav}
      />
    );
  } else if (nav == 3) {
    Screen = <CartProcess4 />;
  }

  //on clicking on steps in cart
  async function handleChangeNav(no) {
    console.log("tttttttttt");
    setNav(no); //sets active step number
    // const token = window.localStorage.getItem("token");
    // let mData = {
    //   billing_lastname: "test",
    //   billing_lat: 43434,
    //   billing_lng: 8999,
    //   billing_address_1: " address 1",
    //   billing_state: true,
    // };
    // await checkout_step(token, mData, "details")
    //   .then((response) => {
    //     console.log("the response is", response);
    //   })
    //   .catch((err) => {
    //     console.log("the eror", err);
    //   });
    localStorage.setItem("process", no); //stores current user step
  }

  return (
    <div className="cartProcess">
      {" "}
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#ffea13"} loading={loading} size={30} />
        </div>
      ) : meals.length == 0 && nav != 3 ? null : (
        <div className="cpTop">
          <div
            onClick={() =>
              nav == 0 || nav == 1 || nav == 2 ? handleChangeNav(0) : null
            }
            className={
              nav == 0 || nav == 1 || nav == 2 || nav == 3 ? "activeStep" : null
            }
          >
            <div
              style={{
                zIndex: "3",
              }}
              className={i18n.language == "en" ? "step1 " : "step1Ar "}
            >
              {" "}
              <div>
                <HiShoppingCart size={35} /> <p> {t("process.details")} </p>{" "}
              </div>
            </div>
          </div>{" "}
          <BsChevronDoubleDown
            style={
              nav == 1 || nav == 2 || nav == 3
                ? {
                    color: "#ffea13",
                  }
                : null
            }
            className="processDown"
            size={30}
          />{" "}
          <div
            onClick={() => (nav == 1 || nav == 2 ? handleChangeNav(1) : null)}
            className={nav == 1 || nav == 2 || nav == 3 ? "activeStep" : null}
          >
            <div
              style={{
                zIndex: "2",
              }}
              className={i18n.language == "en" ? "step " : "stepAr"}
            >
              {" "}
              <div>
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.1466 13.291C16.8165 13.291 13.293 16.8145 13.293 21.1446C13.293 25.4747 16.8147 29 21.1466 29C25.4785 29 29.0002 25.4765 29.0002 21.1464C29.0002 16.8163 25.4767 13.291 21.1466 13.291ZM22.3537 25.375C22.3537 26.042 21.8136 26.5839 21.1448 26.5839C20.476 26.5839 19.9376 26.042 19.9376 25.375V21.1464C19.9376 20.4794 20.4778 19.9374 21.1466 19.9374C21.8154 19.9374 22.3555 20.4794 22.3555 21.1464V25.375H22.3537ZM21.1466 18.1249C20.4778 18.1249 19.9376 17.5848 19.9376 16.916C19.9376 16.249 20.4778 15.707 21.1466 15.707C21.8154 15.707 22.3555 16.2472 22.3555 16.916C22.3537 17.5848 21.8136 18.1249 21.1466 18.1249Z"
                    fill="#ffea13"
                    fillOpacity="0.7"
                  />
                  <path
                    d="M20.5412 7.19884V2.41563C20.5412 1.74862 20.001 1.20668 19.3322 1.20668C18.6652 1.20849 18.1251 1.74862 18.1251 2.41563V4.83533L13.5322 0.343924C13.0628 -0.114641 12.3124 -0.114641 11.8429 0.343924L0.540129 11.393C0.197564 11.7337 0 12.205 0 12.6871C0 13.6858 0.813817 14.4996 1.81251 14.4996H3.62502V21.1461C3.62502 22.8118 4.97896 24.1676 6.64647 24.1676H9.66793C10.3349 24.1676 10.8769 23.6256 10.8769 22.9586C10.8769 22.2916 10.3349 21.7497 9.66612 21.7497H6.64466C6.31116 21.7497 6.0411 21.4796 6.0411 21.1461V13.2925C6.0411 12.6255 5.50097 12.0836 4.83215 12.0836H3.29152L12.6876 2.89775L20.6028 10.639C21.0813 11.1066 21.8462 11.0976 22.312 10.6209C22.7796 10.1442 22.7706 9.37929 22.2939 8.91166L20.5412 7.19884Z"
                    fill="#ffea13"
                  />
                </svg>
                <p> {t("process.orderWay")} </p>{" "}
              </div>
            </div>
          </div>{" "}
          <BsChevronDoubleDown
            style={
              nav == 2 || nav == 3
                ? {
                    color: "#ffea13",
                  }
                : null
            }
            className="processDown"
            size={30}
          />
          <div
            onClick={() => (nav == 2 ? handleChangeNav(2) : null)}
            className={nav == 2 || nav == 3 ? "activeStep" : null}
          >
            <div className={i18n.language == "en" ? "step  " : "stepAr"}>
              {" "}
              <div>
                <FaWallet size={35} /> <p> {t("process.payment")} </p>{" "}
              </div>
            </div>
          </div>
          <BsChevronDoubleDown
            style={
              nav == 3
                ? {
                    color: "#ffea13",
                  }
                : null
            }
            className="processDown"
            size={30}
          />{" "}
          <div
            onClick={() => (nav == 3 ? handleChangeNav(3) : null)}
            className={nav == 3 ? "activeStep" : null}
          >
            <div className={i18n.language == "en" ? "step4 " : "step4Ar "}>
              <div>
                <svg
                  width="30"
                  height="27"
                  viewBox="0 0 30 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.3657 17.9692C16.6802 18.6542 15.7704 19.0312 14.8027 19.0312C13.8349 19.0312 12.9251 18.6542 12.2397 17.9692L7.10486 12.8344C6.41986 12.1494 6.04285 11.2392 6.04285 10.2714C6.04285 9.30367 6.41986 8.39388 7.10486 7.70844C7.7903 7.02344 8.70009 6.64642 9.66785 6.64642C10.6356 6.64642 11.5458 7.02344 12.2308 7.70844L14.8027 10.2807L21.904 3.17896C19.5839 1.20184 16.5807 0 13.2911 0C5.95036 0 0 5.95036 0 13.2911C0 20.6318 5.95036 26.5822 13.2911 26.5822C20.6318 26.5822 26.5839 20.6318 26.5839 13.2911C26.5839 11.9171 26.3755 10.5922 25.9874 9.34526L17.3657 17.9692Z"
                    fill="#C9C9C9"
                  />
                  <path
                    d="M14.8028 16.6134C14.4931 16.6134 14.1846 16.4956 13.9492 16.2598L8.81439 11.125C8.34312 10.6537 8.34312 9.88905 8.81439 9.41601C9.28566 8.94297 10.0503 8.94474 10.5233 9.41601L14.8046 13.6972L26.9394 1.56244C27.4107 1.09117 28.1753 1.09117 28.6483 1.56244C29.1214 2.0337 29.1196 2.79835 28.6483 3.27139L15.6582 16.2616C15.421 16.4956 15.1108 16.6134 14.8028 16.6134Z"
                    fill="#D9D9D9"
                  />
                </svg>{" "}
                <p> {t("process.confirmOrder")} </p>{" "}
              </div>{" "}
            </div>{" "}
          </div>
        </div>
      )}{" "}
      {Screen} 
      {/* <Footer /> */}
    </div>
  );
}

export default CartProcess;
