import React, { useState, useEffect } from 'react';
import { getMyProposals, addProposals } from '../api-nano';
import { IoAddCircleOutline } from 'react-icons/io5';
import { BeatLoader } from 'react-spinners';
import UserNav from "../Components/UserNav";
import Logout from "../Components/Logout";
import { useTranslation } from "react-i18next";
import '../Styles/MyProposals.css';
import { Link } from 'react-router-dom';

const MyProposals = (props) => {
  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newProposal, setNewProposal] = useState({ 
    name: '', 
    content: '', 
    type: 'proposals',
    target_type: 'Tss\\Basic\\Models\\Department',
    target_id: ''
  });
  const [modal, setModal] = useState(false);
  const [branch, setBranch] = useState(JSON.parse(localStorage.getItem("nearBranches")) || []);
  const { t } = useTranslation();

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchProposals();
    console.log(branch);
  }, []);

  const fetchProposals = async () => {
    try {
      const response = await getMyProposals(token);
      setProposals(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching proposals:', error);
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setNewProposal({ ...newProposal, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const dataToSubmit = { ...newProposal };
      if (newProposal.type !== 'reports') {
        delete dataToSubmit.target_type;
        delete dataToSubmit.target_id;
      }
      await addProposals(token, dataToSubmit);
      setNewProposal({ 
        name: '', 
        content: '', 
        type: 'proposals',
        target_type: 'Tss\\Basic\\Models\\Department',
        target_id: ''
      });
      setShowAddModal(false);
      fetchProposals();
    } catch (error) {
      console.error('Error adding proposal:', error);
      setLoading(false);
    }
  };

  const proposalTypes = {
    proposals: { text: 'مقترح', color: '#4CAF50' },
    complaints: { text: 'شكوى', color: '#FFC107' },
    reports: { text: 'بلاغ', color: '#F44336' },
  };

  return (
    <div>
      {modal ? (
        <Logout setModal={setModal} setCartItem={props.setCartItem} />
      ) : null}
      <div className="userProfile">
        <UserNav setModal={setModal} nav={4} />
        <div className="page-container">
          <div className='up-proposal'>
            <h1 className="upTable-title">{t("proposal.title")}</h1>
            <button className="upTable-button" onClick={() => setShowAddModal(true)}>
              <IoAddCircleOutline /> {t("proposal.add-button")}
            </button>
          </div>

          {loading ? (
            <div className="loader-container">
              <BeatLoader color={"#ffea13"} loading={loading} size={15} />
            </div>
          ) : (
            <div className="proposals-container">
              {proposals.length > 0 ? (
                proposals.map((proposal) => (
                  <div key={proposal.id} className="proposal-card">
                    <div className="proposal-type" style={{
                      color: proposalTypes[proposal.type].color,
                      backgroundColor: `${proposalTypes[proposal.type].color}22`
                    }}>
                      {proposalTypes[proposal.type].text}
                    </div>
                    <h4 className="proposal-title">{proposal.name}</h4>
                    <p className="proposal-content">{proposal.content}</p>
                    <p className="proposal-updated_at">{proposal.updated_at}</p>
                  </div>
                ))
              ) : (
                <p className="no-proposals-message">{t("proposal.no-proposal")}</p>
              )}
            </div>
          )}

          {showAddModal && (
            <div className="modal-overlay">
              <div className="modal-content">
                <h2 className="modal-title">{t("proposal.add-title")}</h2>
                <form onSubmit={handleSubmit}>
                  <select
                    name="type"
                    value={newProposal.type}
                    onChange={handleInputChange}
                    required
                  >
                    {Object.entries(proposalTypes).map(([key, value]) => (
                      <option key={key} value={key}>{value.text}</option>
                    ))}
                  </select>
                  <input
                    type="text"
                    name="name"
                    value={newProposal.name}
                    onChange={handleInputChange}
                    placeholder={t("proposal.name")}
                    required
                  />
                  <textarea
                    name="content"
                    value={newProposal.content}
                    onChange={handleInputChange}
                    placeholder={t("proposal.content")}
                    required
                  />
                  {newProposal.type === 'reports' && (
                  <div>
                    <p style={{fontSize: "14px"}}>{t("proposal.reports")}</p>
                    <select
                      name="target_id"
                      value={newProposal.target_id}
                      onChange={handleInputChange}
                      placeholder={t("proposal.target_id")}
                      required
                      style={{width: "100%"}}
                    >
                      {branch.map((el) => (
                        <option key={el.id} value={el.id}>{el.name}</option>
                      ))}
                    </select>
                  </div>
                  )}
                  <div className="button-group">
                    <button type="submit" className="submit-button">{t("proposal.add")}</button>
                    <button type="button" className="cancel-button" onClick={() => setShowAddModal(false)}>{t("proposal.cancel")}</button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyProposals;